import { CommonModule } from '@angular/common';
import { Component, computed, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { IMenuItem } from '../../../models/menu.model';
import { EVFunctions, PSDirective, TextCase2Component } from 'ets-fe-ng-sdk';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { HorizontalLayoutComponent } from '../horizontal-layout/horizontal-layout.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EUserType } from '../../../@ngrx/user/user.model';
import { LoaderComponent } from '../../loader/loader.component';

@Component({
    selector: 'app-dashboard-layout',
    imports: [
        CommonModule,
        RouterModule,
        MatToolbarModule,
        TextCase2Component,
        MatTreeModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        MatSidenavModule,
        PSDirective,
        LoaderComponent,
        MatListModule,
        MatTooltipModule,
    ],
    templateUrl: './dashboard-layout.component.html',
    styleUrl: './dashboard-layout.component.scss'
})
export class DashboardLayoutComponent extends HorizontalLayoutComponent {
  treeControl = signal(new NestedTreeControl<IMenuItem>((node) => node.children));
  protected override isHomePath: string = '/dashboard';
  dataSource = computed(() => {
    const d = new MatTreeNestedDataSource<IMenuItem>();
    d.data = this.menu();
    return d;
  });
  readonly appName = computed(() => this.appService.appName());
  override readonly userName = computed<string>(() =>
    EVFunctions.strConcatenator2(this.authS.user()?.firstName, this.authS.user()?.lastName, ' '),
  );
  readonly userCompanyTitle = computed(() => this.authS.userCompany()?.description);
  readonly appLogo = computed<string>(() => {
    const userType = this.authS.userType();
    // return userType == EUserType.nia
    //   ? '/assets/images/nia.jpg'
    //   :
    return userType == EUserType.cifm
      ? '/assets/images/cifm.jpg'
      : userType == EUserType.ciin
        ? '/assets/images/ciin.png'
        : '/assets/images/logo.png';
  });

  override profileDesc = computed<string>(() =>
    [
      ['Name', EVFunctions.strConcatenator2(this.authS.user()?.firstName, this.authS.user()?.lastName, ' ')],
      // ['Company', this.authS.userCompanyCodeDescription()],
    ]
      .map(([label, value]) => `${label}: ${value || '-'}`)
      .join('\n'),
  );

  override ngAfterViewInit(): void {
    this.expandToCurrentPage();
    this.cdr.detectChanges()
  }
  override ngOnDestroy(): void {
    return super.ngOnDestroy();
  }

  expandToCurrentPage() {
    const chunks = this.router.url.split('/');
    if (chunks.length < 2) return;
    chunks.shift();
    let currentMenuList = this.menu();
    // debugger9*
    for (const path of chunks) {
      const currentMI = currentMenuList.find((x) => x.path == path);
      if (!currentMI) break;
      this.treeControl().expand(currentMI);
      currentMenuList = currentMI.children || [];
    }
  }

  leveller(menu: IMenuItem[], level = -1) {
    if (!menu?.length) return;
    level++;
    for (const item of menu) {
      item.level = level;
      if (item.children) this.leveller(item.children, level);
    }
  }

  hasChild = (_: number, node: IMenuItem) => !!node.children && node.children?.length > 0;
}
