<div class="">{{ authS.approvedForPlatform() }}</div>

<div class="example-container" [class.example-is-mobile]="uS.isMobileSignal()">
  <div class="example-toolbar">
    <mat-toolbar color="primary" class="justify-content-between">
      <div class="d-flex align-items-center">
        <img [src]="appLogo()" class="logo" alt="" />
        <h1 class="example-app-name">{{ appName() }}</h1>
        <button mat-icon-button (click)="snav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <div>
        <div class="rounded-16 header-right-content fs-16 bg-light text-secondary">
          <div class="d-flex align-items-center">
            <!-- @if (!uS.isMobileSignal()) {
              @if (authS.userCompanyCode()) {
                <div class="">
                  <div class=" ">
                    {{ authS.userCompanyCodeDescription() }}
                  </div>
                </div>
              }
            } -->
            <!-- <div class="bg-light text-primary py-0 px-3 rounded-16">
              {{ env().appName }}
            </div> -->

            @if ((userCompanyTitle() || userName()) && !uS.isMobileSignal()) {
              <div class="">
                <div class="companyName rounded-16 ps-16 pe-8">
                  {{ userCompanyTitle() || userName() }}
                </div>
              </div>
            }
            <div
              class="d-flex align-items-center pointer"
              [matMenuTriggerFor]="accountMenu"
              [matTooltip]="profileDesc()"
              [matTooltipShowDelay]="500">
              <mat-icon>account_circle</mat-icon>
            </div>
          </div>
        </div>
        <mat-menu #accountMenu="matMenu" class="">
          <button mat-menu-item routerLink="/profile">Profile</button>
          @if (authS.userCompany()) {
            <button mat-menu-item routerLink="/company">Company</button>
          }
          <button mat-menu-item routerLink="/help/view">Help</button>
          <button mat-menu-item (click)="authS.logout()">Log Out</button>
        </mat-menu>
      </div>
    </mat-toolbar>
  </div>

  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav
      #snav
      class="sidenav"
      [mode]="uS.isMobileSignal() ? 'over' : 'side'"
      [opened]="!uS.isMobileSignal()"
      [fixedInViewport]="uS.isMobileSignal()"
      fixedTopGap="64">
      <div class="scrollContainer" PSBox>
        <mat-tree [dataSource]="dataSource()" [treeControl]="treeControl()" class="example-tree p-16 ps-0">
          <!-- This is the tree node template for leaf nodes -->
          <!-- There is inline padding applied to this node using styles.
              This padding value depends on the mat-icon-button width. -->
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
            <a class="menu-item" mat-button routerLink="{{ node.fullPath }}" routerLinkActive="selected">
              <i class="{{ node.icon }} text-primary" [ngClass]="{ 'pe-8': node.icon }"></i>
              <div>{{ node.label }}</div>
            </a>
          </mat-tree-node>
          <!-- This is the tree node template for expandable nodes -->
          <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
            <div class="mat-tree-node">
              <a mat-button matTreeNodeToggle class="menu-item" routerLinkActive="selected">
                <i class="{{ node.icon }} text-primary" [ngClass]="{ 'pe-8': node.icon }"></i>
                <div class="d-flex justify-content-between w-100">
                  {{ node.label }}
                  <mat-icon class="" [attr.aria-label]="'Toggle ' + node.label">
                    {{ treeControl().isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                  </mat-icon>
                </div>
              </a>
            </div>
            <!-- There is inline padding applied to this div using styles.
                    This padding value depends on the mat-icon-button width.  -->
            <div [class.example-tree-invisible]="!treeControl().isExpanded(node)">
              <div class="d-flex align-items-center">
                <div class="menuVLine" [style.height.px]="groupCont.offsetHeight - 30"></div>
                <div role="group" class="w-100" #groupCont>
                  <ng-container matTreeNodeOutlet></ng-container>
                </div>
              </div>
            </div>
          </mat-nested-tree-node>
        </mat-tree>
      </div>
    </mat-sidenav>

    <mat-sidenav-content class="" [style.marginLeft.px]="300">
      <div class="bodyHead row row-cols-auto gx-2 m-0 align-items-center" #bh>
        <!-- <button mat-icon-button (click)="back()" class="text-light backButton">
          <mat-icon>arrow_back_ios</mat-icon>
        </button> -->
        @if (!isHome()) {
          <a (click)="uS.back()" class="text-light d-flex align-items-center pointer">
            <i class="material-icons">arrow_back</i>
          </a>
          <span>|</span>
          <a routerLink="/" class="text-light d-flex align-items-center">
            <i class="material-icons">home</i>
          </a>
          <span>/</span>
        }
        @if (!uS.isMobileSignal()) {
          @for (mi of breadCrumb(); track mi.link) {
            @if ($last) {
              <span>{{ mi.label }}</span>
            } @else {
              <a routerLink="{{ mi.link }}">{{ mi.label }}</a>

              <span>/</span>
            }
          }
          <!-- @if (appService.pageTitle && appService.pageTitle!=) {
            <span>/ {{ appService.pageTitle }}</span>
          } -->
        }
      </div>
      <div class="body" [style.height]="'calc(100% - ' + bh.offsetHeight + 'px)'">
        <div class="card" #bodyCont id="{{ id }}" PSBox>
          <div class="mb-0 h-100">
            <router-outlet />
          </div>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

@if (!authS.approvedForPlatform()) {
  <div class="coverLoader">
    <loader [loading]="true" text="A minute, we are setting you up..."></loader>
  </div>
}
