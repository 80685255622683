import { Injectable, computed } from '@angular/core';
import { IMenuItem } from '../models/menu.model';
import { Title } from '@angular/platform-browser';
import { EVFunctions } from 'ets-fe-ng-sdk';
import { Config } from '../configs/index.config';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { EUserType } from '../@ngrx/user/user.model';
import { setupMenu } from '../configs/menu-configs/setup.menu';
import { candidateMenu } from '../configs/menu-configs/candidate.menu';
import { superAdminMenu } from '../configs/menu-configs/super-admin.menu';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  pageTitle?: string;
  readonly appName = computed(() => {
    const ut = this.authS.userType();
    return ut ? `${ut == EUserType.nia ? `Insurers'` : ut} Portal` : '';
  });

  formatMenu(menuList: IMenuItem[], parent?: IMenuItem) {
    let cMenuList: IMenuItem[];
    if (menuList) {
      cMenuList = [];
      for (const { children, ...item } of menuList) {
        let cItem: IMenuItem = {};
        Object.assign(cItem, item);
        // debugger;
        const d = Object.isExtensible(cItem);
        cItem.fullPath = cItem.path;
        if (parent?.fullPath) cItem.fullPath = `${parent.fullPath}/${cItem.path || ''}`;
        if (children?.length) cItem.children = this.formatMenu(children, cItem);
        cMenuList.push(cItem);
      }
    }
    return cMenuList!;
  }

  private readonly intermediaryDeskMenu = computed<IMenuItem[]>(() =>
    this.authS.canAccessIntermediary()
      ? [
          {
            label: `Intermediary Desk`,
            path: `intermediary-desk`,
            icon: 'fa fa-users',
            children: [
              // { path: `tied-agent-initiation`, label: `Tied Agent Initiation` },
              // { path: `register-tied-agent`, label: `Register Tied Agent` },
              {
                path: `tied-agents`,
                label: `Tied Agents`,
                children: [
                  { path: `register`, label: `Register Tied Agent` },
                  { path: 'find', label: 'Maintain Tied Agents' },
                  ...(!environment.isAdmin || true
                    ? [
                        { path: `accounts/naicom`, label: `NAICOM Accounts` },
                        { path: `accounts/cifm`, label: `CIFM Accounts` },
                      ]
                    : []),
                  { path: `analysis`, label: `Analysis` },
                ],
              },
              {
                path: `referral-agents`,
                label: `Referral Agents`,
                children: [
                  { path: `register`, label: `Register Referral Agent` },
                  { path: `find`, label: `Maintain Referral Agent` },
                ],
              },
              {
                path: `broker-agents`,
                label: `Broker Agents`,
                children: [
                  { path: `register`, label: `Register Broker Agent` },
                  { path: `application`, label: `Broker Application` },
                ],
              },
            ],
          },
        ]
      : [],
  );

  private readonly ciinMenu = computed<IMenuItem[]>(() =>
    this.authS.canAccessCIIN()
      ? [
          {
            path: 'ciin',
            label: 'CIIN',
            icon: 'fa fa-building',
            children: [
              {
                path: 'set-pins',
                label: 'Set Pins',
              },
              {
                path: 'candidates',
                label: 'Find Candidates',
              },
              { path: `accounts`, label: `Accounts` },
            ],
          },
        ]
      : [],
  );

  private readonly _cifmMenu = computed<IMenuItem[]>(() =>
    this.authS.canAccessCIFM()
      ? [
          {
            path: `cifm`,
            label: `CIFM`,
            icon: 'fa fa-users-gear',
            children: [
              // { path: `find-certificate`, label: `Find Certificate` },
              {
                path: `candidates`,
                label: `Candidates`,
              },
              // {
              // path: `tied-agents`,
              // label: `Tied Agents`,
              // children: [
              { path: 'tied-agents/find', label: 'Tied Agents' },
              // ],
              // },
              { path: `exam/results`, label: `Examination Results` },
              { path: `exam/setup`, label: `Examination Setup` },
              { path: `exam/bank`, label: `Examination Bank` },
              // { path: `take-exam`, label: `Take Exam` },
              { path: `courses`, label: `Courses` },
              { path: `accounts/cifm`, label: `CIFM Accounts` },
              ...(this.authS.isCiin() || this.authS.isCifm() || this.authS.isAdmin()
                ? [{ path: `accounts/ciin`, label: `CIIN Accounts` }]
                : []),
            ],
          },
        ]
      : [],
  );

  private readonly cifmMenu = computed<IMenuItem[]>(() => [
    ...this._cifmMenu(),
    ...this.intermediaryDeskMenu(),
    ...this.ciinMenu(),
    {
      label: `Set Ups`,
      path: `set-ups`,
      icon: 'fa fa-cogs',
      children: [
        {
          path: `parameters`,
          label: `Parameters`,
        },
        ...(this.authS.isCIFMOrAdmin() ? <IMenuItem[]>[{ path: `companies`, label: `Companies` }] : []),
        { path: `agent-types`, label: `Agent Types` },
        { path: `users`, label: `Users` },
        { path: `user-groups`, label: `User Groups` },
      ],
    },
    {
      label: `Admin`,
      path: `admin-desk`,
      icon: 'fa fa-building-user',
      children: [
        {
          label: 'Agent',
          path: 'agent',
          children: [
            {
              label: 'Fraud Investigation',
              path: 'fraud-investigation',
            },
            {
              label: 'Migration',
              path: 'migration',
              children: [
                {
                  label: 'Staged Agents',
                  path: 'staging',
                },
                {
                  label: 'Candidate RIA',
                  path: 'candidate-ria',
                },
                {
                  label: 'Create Legacy Agent',
                  path: 'create-legacy',
                },
              ],
            },
          ],
        },
        {
          label: 'Batch Operations',
          path: 'batch',
        },
        { label: `User Activity`, path: 'user/activity' },
        {
          label: 'Documents',
          path: 'documents',
        },
      ],
    },
  ]);

  private readonly adminMenu = computed<IMenuItem[]>(() => {
    const intermediary = this.intermediaryDeskMenu(),
      _cifmMenu = this._cifmMenu(),
      ciinMenu = this.ciinMenu();

    if (intermediary?.[0])
      intermediary?.[0].children?.push(
        ..._cifmMenu.map(({ icon, ...x }) => x),
        ...ciinMenu.map(({ icon, ...x }) => x),
      );

    return [
      { label: 'Dashboard', path: 'dashboard', icon: 'fa fa-home' },
      ...intermediary,
      {
        label: `Policy Desk`,
        path: `policy-desk`,
        icon: 'fa fa-book',
        children: [
          { path: `fraud`, label: `Fraud` },
          { path: `claim-data`, label: `Claim Data` },
          { path: `production-data`, label: `Production Data` },
        ],
      },
      setupMenu,
      {
        label: `Workflow `,
        path: `workflow-desk`,
        icon: 'fa fa-tasks',
        children: [
          {
            label: 'Overview',
            path: 'overview',
            id: 'MO29',
          },
          {
            label: 'Find Task',
            path: 'task/find',
          },
          {
            label: 'Find Schedules',
            path: 'schedule/find',
          },
          {
            label: 'Create New',
            children: [
              {
                label: 'Tasks',
                path: 'task/create',
              },
              {
                label: 'Schedules',
                path: 'schedule/create',
              },
            ],
          },
        ],
      },
      {
        label: `Reports`,
        path: `report-desk`,
        icon: 'fa fa-note-sticky',
        children: [
          {
            label: 'Accounts',
            path: 'accounts',
          },
          {
            label: 'Agents',
            path: 'agents',
          },
        ],
      },
      {
        label: `Admin`,
        path: `admin-desk`,
        icon: 'fa fa-building-user',
        children: [
          {
            label: 'Agent',
            path: 'agent',
            children: [
              {
                label: 'Activation',
                path: 'activation',
              },
              {
                label: 'Migration',
                path: 'migration',
                children: [
                  {
                    label: 'Agents',
                    path: 'admin',
                  },
                  {
                    label: 'Staged Agents',
                    path: 'staging',
                  },
                  // {
                  //   label: 'Candidate RIA',
                  //   path: 'candidate-ria',
                  // },
                  {
                    label: 'Create Legacy Agent',
                    path: 'create-legacy',
                  },
                ],
              },

              {
                label: 'Fraud Investigation',
                path: 'fraud-investigation',
              },
            ],
          },
          {
            label: 'Batch Operations',
            path: 'batch',
          },
          { label: `User Activity`, path: 'user/activity' },
          {
            label: 'Documents',
            path: 'documents',
          },
          {
            label: 'Help',
            path: 'help/save',
            children: [
              {
                label: 'Admin',
                path: 'admin',
              },
              {
                label: 'Candidate',
                path: 'candidate',
              },
            ],
          },
        ],
      },
    ];
  });

  readonly systemMenu = computed<IMenuItem[]>(() => {
    const adminMenu = this.adminMenu(),
      cifmMenu = this.cifmMenu(),
      candMenu = candidateMenu,
      isSuperAdmin = this.authS.isSuperAdmin();
    // debugger;
    return this.formatMenu(
      isSuperAdmin
        ? superAdminMenu
        : environment.isAdmin
          ? adminMenu
          : environment.isPartner
            ? cifmMenu
            : environment.isCandidate
              ? candMenu
              : [],
    );
  });

  constructor(
    public title: Title,
    public authS: AuthenticationService,
  ) {}

  setTitle(title: string) {
    this.pageTitle = title;
    this.title.setTitle(EVFunctions.strConcatenator2(title, Config.appName, ' | '));
  }

  turnOffScreenCapture() {
    document.getElementsByTagName('body')[0].classList.remove('no-scrsht');
  }

  turnOnScreenCapture() {
    document.getElementsByTagName('body')[0].classList.add('no-scrsht');
  }
}
