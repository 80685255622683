import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SharedAppComponent } from '../../../shared/src/lib/shared-app.component';
import { ToastNotificationsComponent } from '@serene-dev/toast-notifications';

@Component({
    selector: 'app-root',
    imports: [RouterOutlet, ToastNotificationsComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent extends SharedAppComponent {
  constructor() {
    super(); 
    this.uS.config.appName = `NAICOM Portal`;
  }
  override ngAfterViewInit() {
    super.ngAfterViewInit();
  }
  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
