import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AuthStore } from '../../@ngrx/auth/auth.reducer';

@Injectable()
export class AuthenticationInterceptorService implements HttpInterceptor {
  protected store = inject(Store);
  protected actions = inject(Actions);
  authObj = this.store.selectSignal(AuthStore.Selectors.authObj);
  token = this.store.selectSignal(AuthStore.Selectors.token);
  isLoggedIn = this.store.selectSignal(AuthStore.Selectors.isLoggedIn);
  user = this.store.selectSignal(AuthStore.Selectors.user);
  candidate = this.store.selectSignal(AuthStore.Selectors.candidate);

  constructor(public router: Router) {
    // debugger;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      environment.authenticate &&
      (req.url.startsWith(environment.apiBaseUrl!) || req.url.startsWith(environment.apiWorkflowBaseUrl!))
    )
      return next
        .handle(
          req.clone({
            url: req.url,
            headers: req.headers
              // .set('Content-Type', `application/json`)
              .set('Authorization', `${this.token()}`),
            withCredentials:
              // environment.useAuthCookies &&
              req.method?.toUpperCase() != 'DELETE' && req.method?.toUpperCase() != 'PUT',
          }),
        )
        .pipe(
          tap(
            (event: HttpEvent<any>) => {},
            (e: HttpEvent<any>) => {
              // if (e instanceof HttpErrorResponse && e.status == 401) {
              if (e instanceof HttpResponse && e.status == 401) {
                console.error(`About to be loggeed out due to 401 `, e, req);
                this.router.navigateByUrl('/auth/login');
              }
            },
          ),
        );
    else return next.handle(req);
  }
}
