import { ApplicationConfig } from '@angular/core';

import { routes } from './app.routes';

import { sharedAppConfig } from '../../../shared/src/lib/shared-app.config';
import { environment } from '../../../shared/src/environments/environment';
import { ESystem } from '../../../shared/src/lib/models/index.model';

function setVariables() {
  environment.appName = 'CIFM | CIIN | NIA Portal';
  // environment.allowRegistration = false;
  environment.system = ESystem.partner;
  environment.sentryKey =
    'https://9ee85f414c90ad3a955566868404313a@o4507594374250496.ingest.de.sentry.io/4507596107022416';
  return environment;
}
export const appConfig: ApplicationConfig = sharedAppConfig(routes, setVariables());
