import { Injectable } from '@angular/core';
import { BaseService } from '../../../../../../shared/src/lib/services/base.service';
import { map } from 'rxjs';
import { ISR } from '../../../../../../shared/src/lib/models/index.model';
import { IConfiguration, ISaveConfiguration } from './configuration.model';
import { uniq, uniqBy } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService extends BaseService {
  protected override baseURL = 'v1/config';

  fetchAll(query?: { versionNo: number }) {
    return this.get<ISR<IConfiguration>>('', query).pipe(
      map((r) => {
        r?.content?.forEach(
          (x) => (x._naicomEmailList = x.naicomEmailList?.split(',').map((d) => ({ email: d.trim() })) || []),
        );
        return r;
      }),
    );
  }

  save({ _naicomEmailList, ...data }: ISaveConfiguration) {
    const duplicateMapCheck: { [email: string]: boolean } = {};
    data.naicomEmailList = '';
    for (const { email } of _naicomEmailList || []) {
      const _email = email?.trim();
      if (_email?.length > 0 && !duplicateMapCheck[_email]) data.naicomEmailList += ',' + _email;
    }
    data.naicomEmailList = data.naicomEmailList.length ? data.naicomEmailList.slice(1) : undefined;
    return data.id ? this.put<IConfiguration>(data) : this.post<IConfiguration>(data);
  }
}
